body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  text-shadow: -1px -1px 1px #000, 1px 1px 1px #2ba6cb;
}

.dropshadow {
  text-shadow: -1px -1px 1px #000, 1px 1px 1px #2ba6cb;
}

ul {
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
